import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'Home',
            component: () => import('../views/Home')
        },
        {
            path: '/about',
            name: 'About',
            component: () => import('../views/About')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../views/contact.vue')
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('../views/projects.vue')
        },
        {
            path: '/project=:prop&:imgsrc',
            name: 'project',
            component: () => import('../views/project.vue'),
            props: true,
        },
        {
            path: '/projectInfo=:prop&:imgsrc&:id',
            name: 'projectInfo',
            component: () => import('../views/projectInfo.vue'),
            props: true,
        },
       
    ]
});

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
    Vue.use(require('vue-analytics').default, {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
        router,
        autoTracking: {
            page: process.env.NODE_ENV !== 'development'
        }
    })
}

export default router