<template>
  <v-app :style="`font-family:${font}`">
    <span v-if="loader">
      <semipolar-spinner
        style="margin:auto; top:300px"
        :animation-duration="2000"
        :size="85"
        color="#2D4574"
      />
    </span>
    <div v-if="!loader">
      <core-app-bar />
      <core-drawer />
      <v-content>
        <router-view />
      </v-content>
      <core-footer />
    </div>
  </v-app>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";

export default {
  name: "App",

  components: {
    CoreFooter: () => import("./components/Core/AppBar"),
    CoreAppBar: () => import("./components/Core/Footer"),
    CoreDrawer: () => import("./components/Core/Drawer"),
    SemipolarSpinner,
  },
  data: () => ({
    loader: false,
      styleObj:{
    },
  }),
   computed: {
    font: {
      get() {

        return this.$store.state.font;
        
      },
      set(value) {
        this.$store.commit("setFont", value);
      },
    },
  },
  methods: {
 
  },
  created() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 3000);
  },
};
</script>
<style lang="scss">

// * {
//   font-family: 'Montserrat', sans-serif;
//   font-family: 'Noto Naskh Arabic', serif;
// }

::-webkit-scrollbar {
  width: 5px;
  background: #054467;
}

::-webkit-scrollbar-thumb {
  background: #054467;
  width: 1px;
  border-radius: 50px;
}
</style>
