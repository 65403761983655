import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VueI18n from './i18n'
Vue.use(Vuetify);

export default new Vuetify({
    rtl: VueI18n.locale === 'ar',
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#2D4574',
                secondary: '#054467',
                accent: '#1b1716',
                error: '#FF5252',
                info: '#1b1716',
                success: '#4CAF50',
                warning: '#FFC107',
                navColor: '#FAFAFA'
            },
            dark: {
                primary: '#2D4574',
                secondary: '#a67d58',
                accent: '#a67d58',
                error: '#FF5252',
                info: '#1b1716',
                success: '#4CAF50',
                warning: '#FFC107',
                navColor: '#212121'

            },
        },
    },
});