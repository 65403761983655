import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from './plugins/i18n'
import router from './plugins/router'
import store from './plugins/store'
import './plugins/components'
import 'vue-video-section/dist/vue-video-section.css'
import VueVideoSection from 'vue-video-section'
import myDirective from 'vue-touch-card-effect';
import VueYoutube from 'vue-youtube'
 
Vue.use(VueYoutube)
Vue.directive('touch-card', myDirective);
Vue.component('vue-video-section', VueVideoSection)
Vue.config.productionTip = false;

new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');